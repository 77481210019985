<template>
  <ValidationProvider :rules="rules" :name="field" v-slot="{ errors }">
      <div class="relative">
        <slot name="iconFirst"></slot>
        <t-textarea
            :type="isPassword ? 'password' : 'text'"
            :value="val"
            @input="update"
            :name="name"
            :disabled="disabled"
            :placeholder="placeholder"
            :autocomplete="autocomplete ? 'on' : 'off'"
            :variant="errors.length > 0 ? 'badgeError' : variant"
            :tabindex="tabindex"
            :class="[customClass]"
        ></t-textarea>
<!--        <base-icon name="error" v-show="errors.length > 0" :color="''" class="absolute h-5 w-5 top-1/2 transform -translate-y-1/2 right-3" alt="icon error" />-->
        <slot name="iconEnd"></slot>
      </div>
      <BaseError v-if="!noError" :text="errors[0]" />
  </ValidationProvider>
</template>

<script>
import BaseError from "@/utils/validation/components/BaseError";
import themeConfig from "@/themeConfig";
import BaseIcon from "@/utils/icons/BaseIcon";

export default {
  name: 'TextareaField',
  components: {BaseIcon, BaseError},
  props: {
    value: String,
    fieldType: String,
    field: String,
    rules: String,
    placeholder: String,
    isPassword: Boolean,
    noError: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
    },
    customClass: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    variant: String,
    tabindex: [String, Number],
  },
  data() {
    return {
      val: null,
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val){
        this.update(val);
      }
    }
  },
  methods: {
    update(value) {
      this.$emit('input', value)
      this.val = value;
    }
  },


}
</script>
